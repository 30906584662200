export const dailies = [
  { vid: 'https://sarahghp.com/analog-advent/n01.mp4' },
  { vid: 'https://sarahghp.com/analog-advent/n02.mp4' },
  { vid: 'https://sarahghp.com/analog-advent/n03.mp4' },
  { vid: 'https://sarahghp.com/analog-advent/n04.mp4' },
  { vid: 'https://sarahghp.com/analog-advent/n05.mp4' },
  { vid: 'https://sarahghp.com/analog-advent/n06.mp4' },
  { vid: 'https://sarahghp.com/analog-advent/n07.mp4' },
  { vid: 'https://sarahghp.com/analog-advent/n08.mp4' },
  { vid: 'https://sarahghp.com/analog-advent/n09.mp4' },
  { vid: 'https://sarahghp.com/analog-advent/n10.mp4' },
  { vid: 'https://sarahghp.com/analog-advent/n11.mp4' },
  { vid: 'https://sarahghp.com/analog-advent/n12.mp4' },
  { vid: 'https://sarahghp.com/analog-advent/n13.mp4' },
  { vid: 'https://sarahghp.com/analog-advent/n14.mp4' },
  { vid: 'https://sarahghp.com/analog-advent/n15.mp4' },
  { vid: 'https://sarahghp.com/analog-advent/n16.mp4' },
  { vid: 'https://sarahghp.com/analog-advent/n17.mp4' },
  { vid: 'https://sarahghp.com/analog-advent/n18.mp4' },
  { vid: 'https://sarahghp.com/analog-advent/n19.mp4' },
  { vid: 'https://sarahghp.com/analog-advent/n20.mp4' },
  { vid: 'https://sarahghp.com/analog-advent/n21.mp4' },
  { vid: 'https://sarahghp.com/analog-advent/n22.mp4' },
  { vid: 'https://sarahghp.com/analog-advent/n23.mp4' },
  { vid: 'https://sarahghp.com/analog-advent/n24.mp4' },
]